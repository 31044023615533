<script setup lang="ts">
import { useAuthStore } from '~/store/auth.js'

const props = defineProps({
  error: Object,
})

const store = useAuthStore()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <ErrorNotFound
    v-if="error?.statusCode === 404"
    @clearError="handleError"
  ></ErrorNotFound>
  <ErrorGeneric v-else @clearError="handleError"></ErrorGeneric>
</template>
